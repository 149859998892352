import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SectionTitle from 'components/cards/SectionTitle';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { green, blue, red } from '@mui/material/colors';
import Link from '@mui/material/Link';

export default function BannerContact() {

  return (
    <Box
      sx={{
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: '#e7f0e4ff',
      }}
    >
      <SectionTitle
        data={
          {
            "title": "עובדים ביחד",
            "description": [
              "מוזמנים ליצור קשר ונשוחח על איך אפשר לקדם את הפרויקט שלכם ביחד",
            ]
          }
        }
      />

      <Grid container direction="column" spacing={1} alignItems="center">
        <Grid item >

          <Grid container spacing={1}>
            <Grid item >
              <WhatsAppIcon fontSize="small" style={{ color: green[500] }} />
            </Grid>
            <Grid item >
              <Typography variant="body1" color="textPrimary" >
                <Link href="https://wa.me/972586786066" target="_blank" sx={{}}>
                  שליחת הודעה ישירות לואטסאפ
                </Link>
              </Typography>
            </Grid>
          </Grid>

        </Grid>
        <Grid item >

          <Grid container spacing={1}>
            <Grid item >
              <MailOutlineIcon fontSize="small" style={{ color: red[500] }} />
            </Grid>
            <Grid item >
              <Typography variant="body1" color="textPrimary" >
                <Link href="mailto:novaross@gmail.com" target="_blank" sx={{}}>
                  novaross@gmail.com
                </Link>
              </Typography>
            </Grid>
          </Grid>

        </Grid>
        <Grid item >

          <Grid container spacing={1}>
            <Grid item >
              <PhoneEnabledIcon fontSize="small" style={{ color: blue[500] }} />
            </Grid>
            <Grid item >
              <Typography variant="body1" color="textPrimary" >
                <Link href="tel:+972-58-678-6066" target="_blank" sx={{}}>
                  058-678-6066
                </Link>
              </Typography>
            </Grid>
          </Grid>

        </Grid>
        <Grid item >
          <img alt="Green App Logo" src="/images/logos/green-app-logo.svg" />
        </Grid>

      </Grid>
    </Box >
  );
}
