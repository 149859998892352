
const technologiesData = {
  "data": [
    {
      "title": "Spring Boot",
      "image": "/images/technologies/spring-boot-logo.svg",
    },
    {
      "title": "Angular",
      "image": "/images/technologies/angular-logo.svg",
    },

    {
      "title": "React",
      "image": "/images/technologies/react-logo.svg",
    },

    {
      "title": "Flutter",
      "image": "/images/technologies/flutter-logo.svg",
    },
    {
      "title": "Microservices",
      "image": "/images/technologies/microservices-logo.svg",
    },
    {
      "title": "MongoDB",
      "image": "/images/technologies/mongodb-logo.svg",
    },
    {
      "title": "MySQL",
      "image": "/images/technologies/mysql-logo.svg",
    },
    {
      "title": "NodeJS",
      "image": "/images/technologies/nodejs-logo.svg",
    },
    {
      "title": "HELM",
      "image": "/images/technologies/helm-logo.svg",
    },
    {
      "title": "K8",
      "image": "/images/technologies/kubernetes-logo.svg",
    },
    {
      "title": "AWS",
      "image": "/images/technologies/aws-logo.svg",
    },
    {
      "title": "GCP",
      "image": "/images/technologies/google-cloud-logo.svg",
    }
  ]
}

export default technologiesData;