import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SectionTitle from 'components/cards/SectionTitle';
import Typography from '@mui/material/Typography';

export default function BannerPeople() {

  return (
    <Box
      sx={{
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <SectionTitle
        data={
          {
            "title": "אנשים",
            "description": [
              "צוות עם שנים של נסיון בתחום התוכנה",
            ]
          }
        }
      />

      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item sm={12} md={4}>
          <Typography variant="h6" color="textPrimary" >
            איכות ללא פשרות
          </Typography>
          <Typography variant="body1" color="textPrimary">
            המטרה הראשית שלנו הינה לספק את הפתרון שהלקוח זקוק לו. העבודה שלנו מאופיינת ברמת גימור גבוהה ביותר, עם קוד נקי וקל לתחזוקה עתידית.
          </Typography>
        </Grid>
        <Grid item sm={12} md={4}>
          <Typography variant="h6" color="textPrimary" >
            נסיון מהתעשיה
          </Typography>
          <Typography variant="body1" color="textPrimary" >
            כל אחד מחברי הצוות הינו בעל ניסיון עשיר בתעשיית התוכנה. פיתחנו כמות רבה של פרויקטים מכל הסוגים לפי הסנטנדרטים המקובלים בתעשיה. הפתרונות שאנחנו נותנים מבוססים על פרקטיקות וטכנולוגיות עדכניות ביותר.
          </Typography>
        </Grid>
        <Grid item sm={12} md={4}>
          <Typography variant="h6" color="textPrimary" >
            שקיפות מלאה
          </Typography>
          <Typography variant="body1" color="textPrimary">
            כל תהליך הפיתוח הינו שקוף ללקוחותינו באמצעות ממשק ניהול הפרויקט לאורך כל הדרך. לקוחותינו יכולים להתעדכן בכל רגע נתון בקצב ההתקדמות והמשימות של צוות הפיתוח.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
