
const serviceCardsData = {
  "functionalSpec": {
    "image": "/images/home/services-consulting.jpg",
    "title": "אפיון ומחקר",
    "content": "תיעוד הרעיון ודרישות הפרויקט באמצעות מסמך אפיון מפורט"
  },
  "appDevelopment": {
    "image": "/images/home/services-mobile.jpg",
    "title": "פיתוח אפליקציות",
    "content": "פיתוח כל סוגי אפליקציות עבור מערכות הפעלה Android ו iOS"
  },
  "webDevelopment": {
    "image": "/images/home/services-websites.jpg",
    "title": "בניית אתרים",
    "content": "בניית אתרי אינטרנט פשוטים ואפליקציות ווב מורכבות"
  },
  "backendDevelopment": {
    "image": "/images/home/services-backend-development.jpg",
    "title": "פיתוח צד שרת",
    "content": "פיתוח צד שרת במגוון טכנולוגיות על גבי פלטפורמות ענן וחשיפת REST API"
  },
  "softwareSolutions": {
    "image": "/images/home/services-software-solutions.jpg",
    "title": "פתרונות תוכנה",
    "content": "בנייה של פתרונות בהתאמה אישית, הטמעה של תהליכים ומתודולוגיות פיתוח נכונות"
  }
}

export default serviceCardsData;