import React from 'react';
import Typography from '@mui/material/Typography';

export default function Paragraph(props) {
  return (
    <Typography component={'div'} variant="body1" sx={{ marginBottom: 2 }}>
      {props.children}
    </Typography>
  );
}
