export const ROOT = '/';
export const ROOT_INDEX = 0;

export const ARTICLES = '/articles';
export const ARTICLES_INDEX = 1;

export const URL_ARTICLE_APP_PRICE = 'application-development-price';
export const URL_ARTICLE_APP_TECH = 'mobile-application-technologies';
export const URL_ARTICLE_FUNC_SPEC = 'functional-specification-for-application';

export const ARTICLES_APP_PRICE = [ARTICLES, URL_ARTICLE_APP_PRICE].join('/');
export const ARTICLES_FUNC_SPEC = [ARTICLES, URL_ARTICLE_FUNC_SPEC].join('/');
