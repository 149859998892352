import React from 'react';
import './home.css';
import Banner from 'components/banners/Banner'
import BannerServices from 'components/banners/BannerServices'
import TechnologiesHome from 'components/technologies/TechnologiesHome';
import BannerPeople from 'components/banners/BannerPeople';
import BannerContact from 'components/banners/BannerContact';
import Container from '@mui/material/Container';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container maxWidth="xl">
        <Banner />
        <BannerServices />
        <TechnologiesHome />
        <BannerPeople />
        <BannerContact />
        </Container>
      </React.Fragment>
    );
  }
}

export default Home;