import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArticleGrid from 'components/articles/ArticleGrid';
import ApplicationPrice from 'modules/articles/content/ApplicationPrice';
import ApplicationTechnologies from 'modules/articles/content/ApplicationTechnologies';
import * as routes from 'util/routes';
import articlesData from 'modules/articles/articlesData';
import FunctionalSpecification from 'modules/articles/content/FunctionalSpecification';
import BannerArticles from 'components/banners/BannerArticles';
import Box from '@mui/material/Box';
import ContactMini from 'components/contactMini/ContactMini';
import ArticleTitle from 'components/articles/ArticleTitle';

export default function ArticlePage(props) {
  let { articleUrl } = useParams();

  // find the article details according to the article url
  let articleDetails = articlesData.find((x) => x.url === articleUrl);

  return (
    <Fragment>
      <BannerArticles />

      <ArticleTitle>{articleDetails.title}</ArticleTitle>

      <Typography variant="caption" display="block">
        {articleDetails.published}
      </Typography>

      <Typography variant="body1" sx={{ marginBottom: 7 }}>
        {articleDetails.description}
      </Typography>

      <Box sx={{ maxWidth: '1000px', marginBottom: 7 }}>
        {articleDetails.url === routes.URL_ARTICLE_APP_PRICE && <ApplicationPrice />}
        {articleDetails.url === routes.URL_ARTICLE_APP_TECH && <ApplicationTechnologies />}
        {articleDetails.url === routes.URL_ARTICLE_FUNC_SPEC && <FunctionalSpecification />}
      </Box>

      <ContactMini />

      <Typography variant="body1" sx={{ marginTop: 7 }}>
        מאמרים נוספים
      </Typography>

      <ArticleGrid />
    </Fragment>
  );
}
