import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AvatarWithText from 'components/cards/AvatarWithText';
import technologiesData from 'components/technologies/technologiesData';
import SectionTitle from 'components/cards/SectionTitle';

export default function TechnologiesHome() {

  return (
    <Box
      sx={{
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: '#eef4f8ff',
      }}
    >

      <SectionTitle
        data={
          {
            "title": "טכנולוגיות",
            "description": [
              "צוות מנוסה במגוון טכנולוגיות מובילות, אשר עובד לפי הסטנדרטים המקובלים בתעשיית התוכנה",
            ]
          }
        }
      />

      <Grid
        spacing={2}
        container
        alignItems="center"
      >
        {technologiesData.data.map((data) => (
          <Grid item xs={4} sm={3} md={2} key={data.title}>
            <AvatarWithText data={data} />
          </Grid>
        ))}
      </Grid>

    </Box>
  );
}
