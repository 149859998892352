import React from 'react';
import Typography from '@mui/material/Typography';

export default function ArticleHeaderTwo(props) {
  return (
    <Typography variant="h5" sx={{ paddingBottom: 2 }}>
      {props.children}
    </Typography>
  );
}
