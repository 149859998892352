import React from 'react';
import * as routes from 'util/routes';
import { Switch, Route } from 'react-router-dom';
import ArticlePage from './ArticlePage';
import ArticlesMain from './ArticlesMain';
import Container from '@mui/material/Container';

export default function Articles() {

  return (
    <Container maxWidth="xl">
      <Switch>
        <Route exact path={routes.ARTICLES} component={ArticlesMain} />
        <Route path={`${routes.ARTICLES}/:articleUrl`} component={ArticlePage} />
      </Switch>
    </Container>
  );

}
