import React, { Fragment } from 'react';
import ArticleHeaderOne from 'components/articles/ArticleHeaderOne';
import Paragraph from 'components/articles/Paragraph';
import TipSection from 'components/articles/TipSection';

export default function ArticleContent() {
  return (
    <Fragment>
      <ArticleHeaderOne>כמה עולה לפתח אפליקציה?</ArticleHeaderOne>

      <Paragraph>
        זאת אחת השאלות הנפוצות ביותר אצל לקוחות רבים והתשובה לשאלה הזאת היא לא לא פשוטה. במאמר הבא אנו ננסה להציג ולנתח
        את השלבים העיקריים של תהליך הפיתוח וכך ננסה להבין את המרכיבים השונים של תהליך הפיתוח המשפיעים על המחיר הסופי.
      </Paragraph>

      <Paragraph>
        כאשר מפתחים אפליקציה חדשה מאפס, בדרך כלל תהליך הפיתוח כולו יהיה מורכב מהשלבים הבאים:
        <ul>
          <li>הבנה ראשונית של הרעיון: שיחה ראשונית עם הלקוח על מנת להבין את הרעיון הכללי</li>
          <li>אפיון פונקציונאלי: איסוף דרישות עסקיות מהלקוח וכתיבת מסמך אפיון מפורט</li>
          <li>עיצוב מסכים וחווית משתמש: עיצוב מסכי האפליקציה וחווית משתמש</li>
          <li>אפיון טכני: תכנון ארכיטקטורה של כלל המערכת ואופן מימוש הדרישות העסקיות</li>
          <li>קידוד: השלב בו מתבצע קידוד האפליקציה ומערכות נלוות בפועל על ידי צוות הפיתוח</li>
          <li>תחזוקה: תחזוקה שוטפת של הקוד, הוספה של יכולות חדשות ושיפורים לפי דרישה</li>
        </ul>
      </Paragraph>

      <Paragraph>
        המשקל של כל סעיף תלוי באופי האפליקציה ורמת המורכבות שלה. עבור אפליקציות מסוימות אפשר ואף רצוי לדלג על שלבים
        מסוימים. חשוב להפעיל שיקול דעת בכל שלב ושלב על מנת לנסות למקד את העשיה ואת המשאבים לכיוון מועיל וככה להקטין את
        העלות הכוללת של הפרויקט.
      </Paragraph>

      <Paragraph>
        כמו בכל פרויקט שבנוי מכמה שלבים, איכות ירודה של ביצוע של כל אחד מהשלבים עלולה להשפיע לרעה על עלויות של השלבים
        הבאים. כך לדוגמא אם קידוד האפליקציה מתבצע בצורה לא איכותית, עלויות תחזוקה עלולות להיות יקרות הרבה יותר.
      </Paragraph>

      <TipSection>
        ישנה חשיבות רבה למיומנות וניסיון הצוות המלווה את היזם בשלבי הפיתוח ושבונה עבורכם את האפליקציה. כמו כן ישנה
        חשיבות רבה בבחירת הכלים השונים ושפות התכנות איתם הצוות עובד.
        <br />
        עבודה עם כלים ישנים ושפות תכנות לא מתאימות עלולה לגרום למצב בו יהיה קשה מאוד לבצע תחזוקה שוטפת והוספה של יכולות
        חדשות לאפליקציה בהמשך הדרך.
      </TipSection>

      <Paragraph>
        בסעיפים הבאים של המאמר אנו ננסה להבין יותר לעומק את כל אחד מהשלבים ואיך כל שלב עשוי להשפיע על המחיר הסופי.
      </Paragraph>

      <ArticleHeaderOne>הבנה ראשונית של הרעיון</ArticleHeaderOne>

      <Paragraph>
        בשלב זה אנו מקיימים שיח עם הלקוח על הרעיון העומד מאחורי האפליקציה ומנסים להבין את את המרכיבים העיקריים של המערכת
        ואת הפונקציונליות שהאפליקציה צריכה לבצע.
        <br />
        בשלב הזה אנחנו ננסה למקד את שיח באמצעות השאלות הבאות:
        <ul>
          <li>מה הייעוד העיקרי של האפליקציה?</li>
          <li>מי קהל היעד של האפליקציה?</li>
          <li>עבור איזה מערכת הפעלה רוצים לבנות את האפליקציה? אנדרואיד? או iOS? או שניהם?</li>
          <li>האם יש צורך לבצע רישום זיהוי ואימות של משתמשים?</li>
          <li>האם האפליקציה צריכה לשמור ולשלוף מידע ממאגרי נתונים ברשת האינטרנט?</li>
          <li>האם האפליקציה אמורה לרוץ ברקע ולהגיב לתרחישים שונים? לדוגמא זיהוי התקני Bluetooth בקרבה</li>
          <li>האם רוצים לפרסם את האפליקציה בחנויות האפליקציות השונות או לשלוח ללקוחות קובץ התקנה?</li>
          <li>האם יש צורך במערכת ניהול תוכן (back office) דרכה ניתן יהיה לעדכן תוכן של האפליקציה?</li>
        </ul>
        ישנם המון היבטים טכניים נוספים שיש צורך לדון בהם על מנת להבין את העלות הצפויה של הפיתוח.
      </Paragraph>

      <ArticleHeaderOne>אפיון פונקציונאלי</ArticleHeaderOne>

      <Paragraph>
        במידה מחליטים לצאת לדרך ולהתחיל בתהליך הפיתוח האפליקציה, השלב הראשון שאנו נבצע זה כתיבת מסמך אפיון פונקציונאלי.
        <br />
        זהו אחד השלבים הראשונים והקריטיים בתהליך פיתוח אפליקציות וכל סוג אחר של תוכנה. כמו בכל פרויקט, לפני שמתחילים
        לבנות משהו, תחילה יש להבין מה אנחנו הולכים לבנות.
        <br />
        בשלב האפיון אנו אוספים את הדרישות של הלקוח ומבצעים תיעוד מקצועי, מדויק ורשמי של הדרישות העסקיות.
        <br />
        במסמך האפיון הפונקציונאלי אנחנו מתארים את הייעוד של האפליקציה, את היכולות, את האינטראקציה של המשתמשים על המערכת,
        מעצבים את המסכים העיקריים באמצעות wireframes ומתארים את חווית משתמש בכל מסך ומסך.
      </Paragraph>

      <TipSection>
        יצירת wireframes הינו תהליך שבו מתארים באופן מופשת את המידע ואת הפקדים שהמשתמש יראה בכל מסך ומסך. זהו תהליך
        ראשוני ובסיסי ושונה במהותו מתהליך עיצוב המסכים וחווית משתמש.
      </TipSection>

      <Paragraph>
        בסופו של שלב כתיבת אפיון פונקציונאלי מתקבל מסמך אפיון רשמי אשר משמש את כל הגורמים המעורבים בפרויקט כולל היזם,
        המעצבים, צוות הפיתוח ועוד.
        <br />
        האפיון הפונקציונאלי זהו מסמך הכולל תיאור מפורט של היכולות של האפליקציה ושל הפונקציונאליות שלה.
        <br />
        מסמך זה מהווה את התוכנית הביצוע של הפרויקט שממקד את כל הגורמים המעורבים סביב הפרויקט.
        <br />
      </Paragraph>

      <ArticleHeaderOne>עיצוב מסכים וחווית משתמש</ArticleHeaderOne>

      <Paragraph>
        עיצוב נכון של האפליקציה משפיע בצורה ניכרת על האימוץ של האפליקציה על ידי משתמשים פוטנציאלים. העיצוב המסכים וחווית
        משתמש נעשה על ידי מומחה מתחום העיצוב.
        <br />
        בשלב זה מעצבים את הממשק של האפליקציה מבחינה אסתטית ושימושית לפי המוסכמות המקובלות בתחום העיצוב וחווית משתמש.
        עבודת העיצוב כוללת בחירת שילובים של צבעים וגופנים ועיצובים אשר תפקידם לגרום לאפליקציה להיות יותר ידידותית לקהל
        היעד הפוטנציאלי. את המסכים מעצבים בצורה כזאת שיהיו שימושיים שיהיה קל למשתמשים להתמצא באפליקציה ולבצע את
        הפונקציות העיקריות.
        <br />
        חשוב לציין שבהחלט אפשרי לדלג על שלב זה עבור אפליקציות פשוטות או בשלב חקר היתכנות.
        <br />
        ניתן לדלג על שלב העיצוב חווית המשתמש או לדחות אותו למועד מאוחר יותר ולהשתמש ב wireframes שהוגדרו בשלב האפיון,
        כאשר את הממשק ניתן לבנות עם ערכות מוכנות מראש.
        <br />
        יש לקחת את ההחלטה בצורה מודעת, לפי שיקול דעת של היזם והצורך הכללי של הפרויקט באותו הרגע.
        <br />
      </Paragraph>

      <ArticleHeaderOne>אפיון טכני</ArticleHeaderOne>

      <Paragraph>
        לאחר שהגדרנו את מה שהאפליקציה צריכה לעשות ועיצבנו (או לא, לפי שיקול דעת) את מסכי המערכת, יש להתחיל לתכנן איך
        האפליקציה הולכת לממש את המטרות שלה מהבחינה הטכנית. עבור פרויקטים בינוניים ומורכבים המשלבים מספר רב של מערכות
        שצריכות לעבוד ביחד, יש צורך לבצע אפיון טכני של ארכיטקטורת מערכת לפני שמתחילים את שלב הקידוד בפועל.
      </Paragraph>

      <Paragraph>
        בשלב זה אנחנו עושים תכנון ארכיטקטורה של של מרכיבי המערכת, מחליטים על החלוקה הנכונה של הרכיבים השונים במערכת,
        בוחרים את השפות ואת הכלים המתאימים ביותר לצורך ביצוע כל משימה.
        <br />
        כאשר עושים נכון את השלב של האפיון הטכני של המערכת (אפליקציה) בעצם מקבלים כמה יתרונות:
        <ul>
          <li>קיצור שלב הפיתוח של המערכת, על ידי בניית תוכנית מימוש מראש</li>
          <li>הוזלה של עלויות הפיתוח בטווח הרחוק</li>
          <li>גמישות במבנה הצוות וגיוס מהיר של חברי צוות נוספים (מסמך האפיון מהווה מדריך לחברי צוות חדשים)</li>
          <li>
            הצפת קשיים טכניים כבר בשלב התכנון מכיוון שתהליך כתיבת האפיון הטכני מאלץ את מהנדסי התוכנה לחשוב על בעיות
            ולהציע פתרונות עוד לפני תהליך הפיתוח ובכך לחסוך בצורה משמעותית את מאמץ של צוות המפתחים
          </li>
        </ul>
        במילים פשוטות, אם בשלב האפיון הפונקציונאלי אנחנו הגדרנו מה המערכת אמורה לעשות, בשלב האפיון הטכני אנחנו מגדירים
        איך אנחנו נממש את הפונקציונליות שהוגדרה מקודם.
      </Paragraph>

      <ArticleHeaderOne>קידוד</ArticleHeaderOne>

      <Paragraph>
        לאחר שכל ההכנות נעשו וכל התוכניות מוכנות, ניתן להתחיל במלאכת הפיתוח בפועל של האפליקציה (והמערכות הנלוות במידת
        הצורך).
        <br />
        בזכות עבודת ההכנה והתכנון שנעשתה מקודם, צוות הפיתוח יכול להתרכז במשימות הפיתוח בצורה ממוקדת ולא להתעסק בשאלות
        תכנון וארכיטקטורה.
      </Paragraph>

      <TipSection>
        חשוב לוודא שצוות הפיתוח מעודכן בטכנולוגיות ומתודולוגיות פיתוח עדכניות. במידה והפרויקט מבוצע עם טכנולוגיות
        מיושנות, עלול להיווצר קושי רב בתחזוקה של הפרויקט והיכולת להוסיף יכולות חדשות בקלות.
      </TipSection>

      <Paragraph>
        לאחר הקמה של צוות פיתוח הדרוש לביצוע המשימה, מגדירים תפקידים, מחלקים את העבודה לחלקים קטנים ומתחילים בביצוע של
        הפרויקט. מומלץ להתנהל בשלב זה בצורה שקופה ומסודרת, בעזרת כלי ניהול פרויקט.
        <br />
        פיתוח פרויקטים מורכבים דורש קואורדינציה ואינטראקציה טובה בין חברי הצוות, לכן חשוב לבצע את המשימה עם צוותים
        מנוסים שכבר רגילים לעבוד ביחד.
      </Paragraph>

      <Paragraph>
        ראוי לציין שתהליך הקידוד יכול להתחיל לפני שתהליך עיצוב המסכים הסתיים, שכן צוות הפיתוח יכול לבצע את בניית המסכים
        לפי ה wireframes ורק לאחר מכן "להלביש" את העיצובים הסופיים על גבי המסכים.
      </Paragraph>

      <Paragraph>
        ביצוע עבודת פיתוח תוכנה דורש היכרות מעמיקה של הצוות עם עולם הפיתוח. תלוי באופי הפיתוח שיש לבצע, צוות הפיתוח אמור
        להכיר את העקרונות הבאים בתחום התוכנה:
        <ul>
          <li>עקרונות פיתוח ועיצוב תוכנה נכונים</li>
          <li>ארכיטקטורה של מערכות מידע</li>
          <li>עקרונות קוד נקי וכתיבת קוד שניתן לתחזוקה בקלות</li>
          <li>עבודה עם כלי ניהול גרסאות על קוד</li>
          <li>ניהול גרסאות של רכיבי מערכת</li>
          <li>עקרונות עבודה ופקודות לינוקס</li>
          <li>כלי בניית פרוייקטים לדוגמא gradle</li>
          <li>עקרונות וניסיון בעבודה עם קונטיינרים לדוגמא docker</li>
          <li>עבודה עם פלטפורמות ריצת קונטיינרים</li>
          <li>עבודה עם פלטפורמות ענן לדוגמת AWS, GCP</li>
          <li>עבודה עם בסיסי נתונים רלציונים ומבוססי מסמכים לשמירת נתונים</li>
          <li>מתודולוגית עבודה וכתיבה REST API ועבודה עם open api spec</li>
          <li>שיטות בדיקות אפליקציות וכלי בדיקות</li>
          <li>יצירה של תהליכי אוטומציה CI/CD אשר חוסכים זמן רב בפרויקטים מורכבים</li>
          <li>שפות תכנות איתן בונים את האפליקציה בפועל לדוגמא ג'אווה, פלאטר וכדומה</li>
        </ul>
        כפי שניתן לראות מלאכת הפיתוח אינה פשוטה ועלולה להיות המרכיב היקר ביותר בכל תהליך הפיתוח. חשוב לבחור צוות מנוסה
        שמכיר את עולם הפיתוח ויכול לתת מענה מדויק לדרישות הלקוחות בזמן קצר.
      </Paragraph>

      <ArticleHeaderOne>תחזוקה</ArticleHeaderOne>

      <Paragraph>
        בשלב זה מתבצעת תחזוקה שוטפת של המערכת או האפליקציה והוספה של יכולות חדשות לפי דרישת הלקוח. עבור אפליקציות
        מורכבות, תמיד יש צורך לפתח יכולות חדשות לפי הדרישות של השוק והיזמים.
        <br />
        היכולת של להוסיף יכולות חדשות בקלות לאפליקציה קיימת תלויה במידה רבה באיכות שבה בוצע שלב הקידוד והתיעוד של
        האפליקציה.
      </Paragraph>

      <TipSection>
        יש לוודא מול הצוות המבצע באילו כלים נעשה שימוש עבור כל רכיב של האפליקציה ולוודא שלא יהיה קושי בגיוס מפתחים
        נוספים עבור תחזוקת הפרויקט.
      </TipSection>

      <ArticleHeaderOne>לסיכום</ArticleHeaderOne>

      <Paragraph>
        כפי שניתן להתרשם, תהליך הפיתוח הינו מורכב עם הרבה החלטות ומשימות שצריך לבצע אשר דורשות מעורבות של כח אדם מקצועי
        ומיומן.
        <br />
        ניתן לראות שעלות פיתוח האפליקציה תלויה באופן פרופורציונלי למורכבות שלה ויכולה לנוע בין כמה אלפי שקלים בודדים
        לעשרות אלפי שקלים ואף יותר.
        <br />
        לפי הניסיון שלנו ולפי מאמרים רבים באינטרנט ניתן לסכם את עלויות הפיתוח בטווחים הבאים:
        <br />
        <br />
        אפליקציה פשוטה: החל מכמה אלפי שקלים ועד כ 50 אלף שקל
        <br />
        אפליקציה בינונית: בסביבות ה 50-150 אלף שקל
        <br />
        אפליקציה מורכבת: מעל 100 אלף שקל
        <br />
      </Paragraph>

      <Paragraph>
        לנו יש ניסיון והיכרות מעמיקה עם כל שלבי הפיתוח של אפליקציות ומערכות תוכנה שונות, משלב האפיון ועד שלב התחזוקה,
        ואנחנו נשמח ללות אותכם בכל שלב ושלב עם צוות מקצועי ומיומן שנותן מענה ממוקד ויעיל לכל צורך.
      </Paragraph>
    </Fragment>
  );
}
