import React, { Fragment } from 'react';
import ArticleGrid from 'components/articles/ArticleGrid';
import BannerArticles from 'components/banners/BannerArticles';

export default function ArticlesMain() {
  return (
    <Fragment>
      <BannerArticles />

      <ArticleGrid />
    </Fragment>
  );
}
