import React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as routes from 'util/routes';
// use name with lower letter because this is not component
import theme from './Theme'
import Header from 'components/header/Header'
import { ThemeProvider } from '@mui/styles';
import { BrowserRouter } from 'react-router-dom'
import Home from 'modules/home/Home'
import Footer from 'components/footer/Footer'
import Articles from 'modules/articles/Articles';

class App extends React.Component {
  render() {
    return (
      // the custom theme is provided to the whole app
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header />
            <Switch>
              <Route exact path={routes.ROOT} component={Home} />
              <Route path={routes.ARTICLES} component={Articles} />
            </Switch>
          <Footer title="Footer" description="Something here to give the footer a purpose!" />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
