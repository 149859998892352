import React, { Fragment } from 'react';
import ArticleHeaderOne from 'components/articles/ArticleHeaderOne';
import ArticleHeaderTwo from 'components/articles/ArticleHeaderTwo';
import ArticleHeaderThree from 'components/articles/ArticleHeaderThree';
import Paragraph from 'components/articles/Paragraph';
import TipSection from 'components/articles/TipSection';

export default function ArticleContent() {
  return (
    <Fragment>
      <Paragraph>
        קיימות כמה שיטות פיתוח שבאמצעותן ניתן לפתח אפליקציות מובייל עבור מכשירים ניידים. על מנת לבחור את השיטה
        והטכנולוגיה המתאימה והיעילה ביותר, קודם יש להבין לעומק את היכולות שהאפליקציה אמורה לבצע ואת הטכנולוגיות הקיימות
        בשוק. לאחר שקלול מושכל של כל הפרמטרים ניתן להתאים את שיטת הפיתוח המתאימה והיעילה ביותר עבור האפליקציה. בכתבה זו
        אנו ננסה להבין מה מייחד אפליקציית מובייל באופן כללי, איזה טכנולוגיות קיימות ומה מייחד כל סוג של טכנולוגיה.
      </Paragraph>

      <ArticleHeaderOne>מה מאפיין אפליקצית מובייל</ArticleHeaderOne>

      <Paragraph>
        טלפונים ניידים הפכו לחלק חיוני מהחיים של כולם. אנו משתמשים בטלפונים שלנו בכל שעה ובכל יום כדי לגשת למידע
        באינטרנט, לתקשר עם אנשים אחרים, לבצע עסקאות, לצרוך מוצרים, לתכנן את היום שלנו, לשתף את מחשבותינו, לנווט למקומות
        חדשים ועוד. אנחנו עושים את כל זה באמצעות האפליקציות השונות המותקנות על המכשירים הניידים שלנו. השימוש הגובר
        באפליקציות מגביר את הצורך פיתוח אפליקציות חדשות למכשירים הניידים.
      </Paragraph>

      <Paragraph>
        אפליקציית מובייל היא בעצם תוכנה קטנה שנועדה לעבוד על גבי מכשיר נייד, לדוגמא טלפון סלולרי או טאבלט. למרות שאותן
        האפליקציות הן בעצם יחידות תוכנה קטנות עם פונקציות מוגבלות לרוב (בשונה למשל מאתרי אינטרנט, שלהם יש אפשרות רחבה
        יותר של פונקציונליות כמו אתרי בנק למשל) הן עדיין מצליחות לספק למשתמשים שירות וחוויה איכותיים. אפליקציות המובייל
        מספקות לרוב פונקציונליות מבודדת וממוקדת למטרה מסוימת. זה יכול להיות משחק, מחשבון, טיימר, אפליקצית מזג האויר או
        דפדפן אינטרנט נייד.
      </Paragraph>

      <Paragraph>
        בתחילת דרכם של הטלפונים הסלולריים משאבי החומרה המוגבלים שלהם מנעו מאפליקציות פונקציונליות מרובה, אך למרות שהיום
        מכשירי הסלולר מאוד מתקדמים ואף מחליפים לפעמים את המחשבים האישיים שלנו, רוב אפליקציות המובייל נשארו יחסית
        מצומצמות ועדיין מתמקדות במספר שימושים ספציפיים כלפי המשתמש.
      </Paragraph>

      <ArticleHeaderOne>טכנולוגיות פיתוח אפליקציות</ArticleHeaderOne>

      <Paragraph>
        ניתן לנסות ולחלק את עולם הטכנולוגיות לבניית אפליקציות לשלוש קטגוריות על ראשיות:
        <ul>
          <li>אפליקציות נייטיב Native Application</li>
          <li>אפליקציות ווב Web Applications</li>
          <li>אפליקציות היברידיות Hybrid Applications</li>
        </ul>
      </Paragraph>

      <ArticleHeaderTwo>אפליקציות נייטיב</ArticleHeaderTwo>

      <Paragraph>
        אפליקציות נייטיב הינן אפליקציות אשר נוצרות לשימוש אך ורק בפלטפורמה אחת או למערכת הפעלה אחת ספציפית. כן לדוגמא
        אפליקצית נייטיב לאנדרואיד מתאימה רק למערכת הפעלה אנדרואיד ולא ניתן להשתמש בקוד של אפליקציה על מנת לבנות אפליקציה
        זהה עבור מערכת הפעלה iOS. היתרון של אפליקציות נייטיב הוא בכך שהן מאפשרות את הביצועים הטובים ביותר ואת מגוון
        היכולות העשיר ביותר עבור אותה הפלטפורמה.
      </Paragraph>

      <ArticleHeaderTwo>אפליקציות ווב</ArticleHeaderTwo>

      <Paragraph>
        אפליקציות ווב הינן גרסאות רספונסיביות של אתרי אינטרנט אשר מותאמים לעבוד על גבי מכשירים סלולריים ומסך קטן יותר.
        אפליקציות ווב אינן אפליקציות עצמאיות אשר ניתן להוריד ולהתקין על גבי המכשיר. אפליקציה מסוג זה מאוחסנת על גבי שרת
        אינטרנט ומציגה את התוכן שלה באמצעות דפדפן האינטרנט של המכשיר הנייד תוך התאמת הממשק לפי גודל המסך בו היא מוצגת.
      </Paragraph>

      <ArticleHeaderTwo>אפליקציות היברידיות</ArticleHeaderTwo>

      <Paragraph>
        אפליקציות היברידיות מאופיינות בכך שהן המאפשרות כתיבה של קוד האפליקציה פעם אחת, כך שהקוד יודע לרוץ על כמה מערכות
        הפעלה שונות. זוהי השיטה הנפוצה ביותר לכתיבת אפליקציות כיום בגלל שהשיטה מאפשרת חיסכון בעלויות של פיתוח עבור
        מערכות הפעלה מרובות ולהנות מביצועים טובים של האפליקציה ברוב המקרים.
      </Paragraph>

      <TipSection>
        אפליקציות היברידיות הינן פופולריות מכיוון שהן מאפשרות למפתחים לכתוב את הקוד פעם אחת ולהתאימו לפלטפורמות ריצה
        שונות. שיטת פיתוח זו מאפשרת לחסוך עלויות ומאופיינת ביתרונות רבים נוספים.
      </TipSection>

      <ArticleHeaderOne>אפליקציות נייטיב</ArticleHeaderOne>

      <ArticleHeaderTwo>אנדרואיד נייטיב</ArticleHeaderTwo>

      <Paragraph>
        כאשר מדובר באפליקציות נייטיב לאנדרואיד, הכוונה היא לאפליקציות אשר נכתבו באחת מהשפות עבור מערכת הפעלה זו, שהן
        ג'אווה וקוטלין.
      </Paragraph>

      <ArticleHeaderThree>ג'אווה - Java</ArticleHeaderThree>

      <Paragraph>
        שפת ג'אווה הייתה השפה הראשית הרשמית לפיתוח אפליקציות אנדרואיד עד אשר שפת קוטלין הצטרפה לארגז הכלים של מפתחי
        אנדרואיד. באופן כללי ג'אווה היא גם אחת שפות התכנות הנפוצות ביותר. הרבה מאוד מהאפליקציות בחנות ה Google Play
        בנויות באמצעות שפת ג'אווה. ג'אווה היא גם השפה הכי נתמכת ע''י גוגל. לשפת ג'אווה יש קהילה עולמית גדולה ותומכת מאוד
        מהיותה שפה פופולרית. למרות זאת, יש שיגידו ששפת ג'אווה יכול להיות מורכבת במקצת למפתחים מתחילים, ועם זאת ערכת
        פיתוח התוכנה של אנדרואיד (SDK) מגדילה את המורכבות שלה לרמה חדשה. בסך הכל, ג'אווה היא שפה נהדרת לחוות את אופן
        הפיתוח של אפליקציית אנדרואיד.
      </Paragraph>

      <ArticleHeaderThree>קוטלין - Kotlin</ArticleHeaderThree>

      <Paragraph>
        קוטלין הינה השפה הרשמית לפיתוח אפליקציות אנדרואיד אשר הוכרזה על כך בשנת 2019 אך גוגל החלה לתמוך בה עוד בשנת
        2017. קוטלין היא שפה חוצה פלטפורמות (יכולה לעבוד גם על גבי מכשירי אנדרואיד וגם מכשירי iOS) אשר יכולה לשמש כחלופה
        לשפת ג'אווה לפיתוח אפליקציות אנדרואיד. קוטלין יכולה לעבוד בשיתוף פעולה עם ג'אווה והיא רצה על גבי מכונה וירטואלית
        מסוג ג'אווה. קוטלין גם נחשבת לשפה מסודרת ונקייה יותר משפת ג'אווה.
      </Paragraph>

      <ArticleHeaderTwo>אייפון נייטיב</ArticleHeaderTwo>

      <Paragraph>
        כאשר מדובר באפליקציות נייטיב עמור מערכת הפעלה iOS, הכוונה היא לאפליקציות אשר נכתבו לרוב בסוויפט או ב
        Objective-C.
      </Paragraph>

      <ArticleHeaderThree>סוויפט - Swift</ArticleHeaderThree>

      <Paragraph>
        סוויפט היא שפת התכנות העיקרית של מערכת ההפעלה iOS. סוויפט פותחה והושקה על ידי אפל בשנת 2014. לפני סוויפט,
        Objective-C הייתה השפה העיקרית לפיתוח עבור מערכת הפעלה של אייפון. Objective-C, בהיותה בת 30 שנה, לא תמכה בצרכים
        המודרניים. לעומתה, Swift היא שפת תכנות מודרנית המספקת תכונות שפה מודרניות כגון: דינמיות, בטיחות ואבטחה, כריכה
        מאוחרת והרחבה. סוויפט נועדה לעבוד היטב עם פריימוורקס כמו קקאו וקקאו טאץ. קקאו היא מכלול של פריימוורקס מונחות
        עצמים אשר מספקת סביבת עבודה או ריצה עבור יישומים הפועלים על סביבת עבודה של אפל ועל כלל מכשיריה. מלבד iOS, סוויפט
        היא גם שפת תכנות של מכשירים נוספים באקוסיסטם של אפל כמו שעונים חכמים, סטרימרים של אפל ועוד.
      </Paragraph>

      <ArticleHeaderOne>אפליקציות היברידיות</ArticleHeaderOne>

      <Paragraph>
        אפליקציה היברידית היא אפליקציה אשר מפתחים אותה באמצעות קוד יחיד אשר מסוגל לרוץ על מספר מערכות הפעלה. זהו יתרון
        עצום עבור מפתחי אפליקציות ולקוחות. במקום לפתח שתי אפליקציות נפרדות, אחת עבור מערכת הפעלה אנדרואיד והשניה עבור
        iOS, באמצעות טכנולוגיה של פיתוח אפליקציות היברידיות ניתן לפתח את האפליקציה פעם אחת ולהריץ אותה על שתי מערכות
        הפעלה.
      </Paragraph>

      <TipSection>
        קוד של אפליקציה היברידית נכתב פעם אחת ומתאים להרצה על כמה פלטפורמות. הדבר מקצר באופן משמעותי את זמן הפיתוח
        והעלויות בהשוואה לאפליקציות נייטיב אשר הדורשות פיתוח עבור iOS ופיתוח עבור Android בצורה נפרדת.
      </TipSection>

      <Paragraph>
        יתרונות בפיתוח אפליקציה היברידית:
        <ul>
          <li>
            פיתוח מהיר יותר: במקום לפתח שתי אפליקציות שונות, באמצעות שיטה זו אנחנו מפתחים פעם אחת אפליקציה מתאימה לשתי
            מערכות הפעלה
          </li>
          <li>
            התנהגות כמעט זהה בכל מערכת הפעלה: הלוגיקה העסקית של האפליקציה נכתבת פעם אחת, הדבר מבטיח התנהגות זהה של
            האפליקציה בשתי פלטפורמות ניידות
          </li>
          <li>תחזוקה קלה יותר: לאפליקציה היברידית קוד יחיד, תיקון הקוד במקום אחד מאפשר תיקון התקלות בשתי הפלטפורמות</li>
          <li>חיסכון בעלויות: שימוש בשיטת פיתוח היברידית מקצר את זמן הפיתוח ומאפשר חיסכון במשאבים ועלויות פיתוח</li>
        </ul>
        שתי טכנולוגיות מובילות כיום בשוק לבניית אפליקציות היברידיות הן ריאקט נייטיב ופלאטר.
      </Paragraph>

      <ArticleHeaderTwo>ריאקט נייטיב</ArticleHeaderTwo>

      <Paragraph>
        ריאקט נייטיב React Native הינו פריימוורק פופולרי המבוסס על ג'אווה סקריפט המאפשר בניה של אפליקציות היברידיות
        למכשירים ניידים עבור מערכות הפעלה אנדרואיד ו iOS. הפריימוורק מאפשר ליצור יישום עבור פלטפורמות שונות באמצעות אותו
        בסיס קוד. ריאקט נייטיב שוחררה לראשונה על ידי פייסבוק כפרויקט קוד פתוח בשנת 2015. רק כעבור כמה שנים, היא הפכה
        לאחד הפתרונות המובילים המשמשים לפיתוח נייד. באמצעות ריאקט נייטיב פותחו כמה מהאפליקציות המובילות בעולם, בינהן
        אינסטגרם, פייסבוק, סקייפ, פינטרסט ועד. ריאקט נייטיב נבנתה בהתבסס על ריאקט, שהינה ספריית ג'אווה סקריפט שכבר הייתה
        מאוד פופולרית לפני כן.
      </Paragraph>

      <ArticleHeaderTwo>פלאטר</ArticleHeaderTwo>

      <Paragraph>
        פלאטר Flutter הינו פריימוורק חינמי לפיתוח אפליקציות מובייל היברידיות מבית היוצר של גוגל. פלאטר שוחררה בשנת 2017,
        והיא מאפשרת למפתחים לבנות אפליקציות עבור מערכות הפעלה אנדרואיד ו iOS על בסיס קוד יחיד. יכולת זו הופכת את עניין
        בניית אפליקציות לפשוטה, מהירה ויעילה יותר. מפתחים הבונים אפליקציות בשימוש פלאטר יעשו זאת באמצעות שפת תכנות
        הנקראת דארט. עסקים בכל הגדלים מסביב לעולם בוחרות בפלאטר לבניית אפליקציות, ביניהן גוגל, BMW, עליבאבא, איביי,
        טויוטה ועוד.
      </Paragraph>

      <ArticleHeaderTwo>פלאטר או ריאקט נייטיב?</ArticleHeaderTwo>

      <Paragraph>
        למעשה אין כאן תשובה אחידה וחד משמעית. ריאקט נייטיב היא בחירה טובה עבור פרויקטים עם פונקציונליות פשוטה ואפליקציות
        חברתיות או תקשורתיות שונות, כגון אפליקציות אירועים, מדיה חברתית, מסחר אלקטרוני ואפליקציות לקוחות. אם מדובר בעסק
        מבוסס עם כמות הגונה של לקוחות אפליקציה לנייד אינה המוצר העיקרי שלה, בחירה בריאקט נייטיב תהיה הבחירה הטובה,
        מכיוון שהוא עוזרת לשווק אפליקציות שנראות כמו אפליקציות נייטיב במהירות משמעותית.
      </Paragraph>

      <Paragraph>
        לעומת זאת, פלאטר תתאים בצורה נהדרת עבור פרויקטים אשר דורשים ביצועים ועיצובים מורכבים יותר ואנימציות מורכבות.
        בנוסף לכך, פלאטר תתאים לסטארט-אפים בתחילת דרכם אשר מעוניינים לשווק מוצר או להוכיח רעיון מסוים עם פונקציות
        מינימליות בתקציב קטן, ומחפשים למצוא התאמה של השוק לגבי המוצר שלהם.
      </Paragraph>

      <TipSection>
        בתקופה האחרונה הפופולאריות של פלאטר עולה, וזאת מהסיבה שיותר ויותר מפתחים וחברות בוחרות בפלאטר ככלי איתו הם
        הולכים לפתח את האפליקציה הבאה מסיבות טכניות. אישית אנו ממליצים להשתמש בפלאטר עבור בניית אפליקציה היברידית הבא
        שלכם.
      </TipSection>

      <ArticleHeaderOne>שרת אפליקציה</ArticleHeaderOne>

      <Paragraph>
        כיום, רוב האפליקציות המוכרות לנו דורשות רכיב הנקרא שרת אפליקציה. לרוב, כאשר אנשים חושבים על רעיון של פיתוח
        אפליקציה, הם מדמיינים את החלק הקדמי שלה: אילו אלמנטים יהיו גלויים לעין, מה יהיה צבעם, אילו כפתורים יהיו בה ומה
        בעצם יגרום לה לבלוט יותר מעל השאר. בעוד שחלקו של החלק הקדמי, הפרונט אנד, כבודו במקומו מונח, לא כולם רואים את
        החלק האחורי של האפליקציה, את שרת האפליקציה, מאחר והחלק הזה איננו גלוי למשתמש הממוצע.
      </Paragraph>

      <Paragraph>
        אפשר להסביר את החשיבותו של השרת בצורה טובה אם מדמים את האפליקציה למסעדה. הלקוח בא למסעדה ומתיישב בשולחן. הלקוח
        יוצר קשר עם המלצר על מנת להזמין את הארוחה שלו. ניתן לדמות פעולה זו למשתמש אשר מבצע פעולה כלשהי באמצעות
        אינטראקציה עם ממשק המשתמש של האפליקציה. ברגע שהלקוח סיים לבצע את ההזמנה שלו, ההזמנה נלקחת אל תוך המטבח להכנתה.
        ברוב המקרים הלקוח אינו רואה את מה שקורה בתוך המטבח, הוא רק רואה את ההזמנה יוצאת מהמטבח ומועברת אל שולחנו.
      </Paragraph>

      <Paragraph>
        המטבח בעצם מתפקד כשרת האפליקציה. השרת אינו מבצע אינטראקציה עם הלקוח או המשתמש, תפקידו לעבד בקשות ולייצא תוכן.
        ללא המטבח (השרת) המסעדה (האפליקציה) לא הייתה מתפקדת, ההזמנות לא היו נעשות וחווית הלקוחות הייתה קטסטרופלית בלשון
        המעטה. כפי שלמטבח יש חלק מאוד גדול בחווית הלקוח והאוכל שלו במסעדה, ככה גם השרת הוא חלק בלתי נפרד מרוב
        האפליקציות.
      </Paragraph>

      <TipSection>
        למרות שאנחנו לא חושבים על שרת אפליקציה כאשר אנחנו חושבים על אפליקציה, רוב האפליקציות בימינו משתמשות בשרת על מנת
        לאחסן את התוכן הדינאמי ולבצע את רוב הפעולות הקשורות ללוגיקה העסקית. כחצי מעלויות פיתוח של אפליקציה חדשה נובעות
        מעליות פיתוח רכיב שרת האפליקציה.
      </TipSection>

      <Paragraph>
        בימינו, רוב האפליקציות זקוקות לרכיב השרת על מנת לאחסן ולעבד את המידע העסקי. כאשר אנחנו חושבים על פיתוח אפליקציה
        חשוב לזכור ולקחת בחשבון עלויות הקשורות לפיתוח השרת.
      </Paragraph>
    </Fragment>
  );
}
