import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

export default function ContactMini() {

  return (
    <Box >
      <Typography variant="subtitle2" >
        יצירת קשר
      </Typography>

      <Link href="https://wa.me/972586786066" target="_blank" sx={{}}>
        <Stack direction="row" sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Box sx={{ marginLeft: 2 }}>
            <img alt="whatsapp" src="/images/icons/whatsapp-icon.svg" width='20' height='20' />
          </Box>
          <Typography variant="body2" >
            וואטסאפ 058-678-6066
          </Typography>
        </Stack>
      </Link>

      <Link href="tel:+972-58-678-6066" target="_blank" sx={{}}>
        <Stack direction="row" sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Box sx={{ marginLeft: 2 }}>
            <img alt="phone" src="/images/icons/phone-icon.svg" width='20' height='20' />
          </Box>
          <Typography variant="body2" >
            נייד 058-678-6066
          </Typography>
        </Stack>
      </Link>

    </Box >
  );
}