import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://www.green-app.co.il">
        Green App
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

function ContactDetails() {
  return (
    <Typography variant="caption" display="block" color="textSecondary" align="center">
      058-678-6066 | novaross@gmail.com
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >

      <Copyright />
      <ContactDetails />

    </Box>

  );
}
