import React from 'react';
import Typography from '@mui/material/Typography';

export default function ArticleHeaderTree(props) {
  return (
    <Typography variant="h6" sx={{ paddingBottom: 2 }}>
      {props.children}
    </Typography>
  );
}
