import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';

export default function ServiceCard(props) {
  const data = props.data;
  const theme = useTheme();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        paddingBottom: 3,
        paddingLeft: 1,
        paddingRight: 1,
      }}
    >
      <Grid item >
        <Typography variant="h4"
          sx={{
            textAlign: 'center'
          }}>
          {data.title}
        </Typography>
        <Box
          sx={{
            
            backgroundColor: `${theme.palette.common.greenApp}`,
            // display: 'block',
            width: 200,
            height: 2,
            marginTop: 1,
            marginBottom: 1,
          }}
        />
      </Grid>
      <Grid item >
        {data.description.map((data) => (
          <Typography variant="body1" key={data}
            sx={{
              textAlign: 'center'
            }}>
            {data}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
}