import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import * as routes from 'util/routes';
import * as hebMapping from 'util/hebMapping';
import AppBar from '@mui/material/AppBar';
import { Container, } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';
import { green, blue, red } from '@mui/material/colors';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

export default function Header() {
  // useState is a hook
  // Hooks are a new addition in React 16.8. They let you use state and other React features without writing a class.
  // useState returns a pair: the current state value and a function that lets you update it
  // The only argument to useState is the initial state
  const [activeTabIndex, setActiveTabIndex] = useState(routes.ROOT_INDEX);

  const handleChange = (event, activeTabIndex) => {
    setActiveTabIndex(activeTabIndex);
  }

  // Hooks are a new addition in React 16.8. They let you use state and other React features without writing a class.
  // fix for setting the tab indicator on the correct tab if the page is refreshed on navigating via link
  // specify the code to be executed each time the component is refreshed
  // The Effect Hook, useEffect, adds the ability to perform side effects from a function component
  // It serves the same purpose as componentDidMount, componentDidUpdate, and componentWillUnmount in React classes, but unified into a single API
  useEffect(
    () => {
      // if trying to access the home, and the value of the activeTabIndex has not been set, then set it
      if (window.location.pathname === routes.ROOT && activeTabIndex !== routes.ROOT_INDEX) {
        setActiveTabIndex(routes.ROOT_INDEX);
      }
      else if (window.location.pathname.includes(routes.ARTICLES) && activeTabIndex !== routes.ARTICLES_INDEX) {
        setActiveTabIndex(routes.ARTICLES_INDEX);
      }
    },
    // passing an array of dependencies that are used in the useEffect function
    // this tells the useEffect to run the code only if the value of one of the objects in the array has changed
    [activeTabIndex]
  );

  return (
    <React.Fragment>
      <AppBar elevation={0} position="static" sx={{ backgroundColor: "white" }}>
        <Container maxWidth="xl">

          <Toolbar
            sx={{}}
          >
            <Box sx={{}}>
              <Tabs
                value={activeTabIndex}
                onChange={handleChange}
              >
                <Tab sx={{ fontFamily: "Rubik", fontSize: 15 }} label={hebMapping.TAB_MAIN} component={RouterLink} to={routes.ROOT} />
                <Tab sx={{ fontFamily: "Rubik", fontSize: 15 }} label={hebMapping.TAB_ARTICLES} component={RouterLink} to={routes.ARTICLES} />
              </Tabs>
            </Box>

            <Box sx={{ marginRight: 'auto' }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item  >
                  <Link href="mailto:novaross@gmail.com" target="_blank" >
                    <MailOutlineIcon fontSize="medium" style={{ color: red[500] }} />
                  </Link>
                </Grid>
                <Grid item >
                  <Link href="https://wa.me/972586786066" target="_blank" >
                    <WhatsAppIcon fontSize="medium" style={{ color: green[500] }} />
                  </Link>
                </Grid>
                <Grid item  >
                  <Link href="https://www.linkedin.com/in/ruslan-spivak" target="_blank" >
                    <LinkedInIcon fontSize="medium" style={{ color: blue[500] }} />
                  </Link>
                </Grid>
                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Link href="https://www.green-app.co.il" >
                    <img alt="Green App Logo" src="/images/logos/green-app-logo.svg" height='45' />
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment >
  );
}