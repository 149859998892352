import React from 'react';
import Typography from '@mui/material/Typography';

export default function ArticleHeaderOne(props) {
  return (
    <Typography variant="h4" sx={{ paddingBottom: 2 }}>
      {props.children}
    </Typography>
  );
}
