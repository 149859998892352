import React, { Fragment } from 'react';
import ArticleHeaderOne from 'components/articles/ArticleHeaderOne';
import ArticleHeaderTwo from 'components/articles/ArticleHeaderTwo';
import Paragraph from 'components/articles/Paragraph';
import TipSection from 'components/articles/TipSection';

export default function FunctionalSpecification() {
  return (
    <Fragment>
      <ArticleHeaderOne>מה זה מסמך אפיון פונקציונאלי?</ArticleHeaderOne>

      <Paragraph>
        אפיון פונקציונאלי זהו מסמך רשמי המתאר בצורה מובנית את הפונקציות והיכולות השונות שהמערכת צריכה לבצע מנקודת ראות
        של משתמש הקצה.
      </Paragraph>

      <Paragraph>
        מסמך זה מתאר בצורה ברורה את היעוד של האפליקציה או המערכת, את הרכיבים השונים מהם היא בנויה, את הפונקציות שהיא
        צריכה לבצע, תיאור סכמטי של מסכי המערכת ואת דרכי התממשקות של המערכת עם מערכות אחרות במידת הצורך.
      </Paragraph>

      <Paragraph>
        המסמך נכתב בדרך כלל באנגלית ומכיל מלל רך, תרשימי ארכיטקטורה סכמטיים של המערכת, עיצובים סכמטיים של המסכים השונים
        של המערכת וכל תוכן אחר אשר נדרש לתיאור המערכת אותה רוצים לבנות. מסמך זה מתאר את היכולות שהמשתמשים של המערכת
        אמורים להיות מסוגלים לבצע כאשר הם באים במגע עם המערכת ואת הקלט והפלט הנדרש עבור ביצוע כל תרחיש עסקי.
      </Paragraph>

      <Paragraph>
        מסמך אפיון פונקציונאלי לא מתאר את האפליקציה או המערכת בצורה טכנית מבפנים ולא מכיל תיאור של דרכי המימוש של
        היכולות השונות. במקום, המסמך מדבר מנקודת מבט של גורמים חיצוניים: אנשים שמשתמשים באפליקציה ומערכות חיצוניות אחרות
        שמתממשקות עם המערכת המדוברת. המסמך האפיון הפונקציונאלי מתאר את ההתנהגות של המערכת שהגורמים החיצוניים "רואים"
        כאשר הם מבצעים פעולות במערכת. לדוגמא: "כאשר המשתמש לוחץ על הכפתור אז נפתחת חלונית שמציגה למשתמש מידע נוסף על
        הפריט".
      </Paragraph>

      <TipSection>
        את תהליך כתיבת האפיון הפונקציונאלי יש לבצע באמצעות גורם מקצועי אשר מנוסה בתחום פיתוח אפליקציות ומערכות תוכנה
        שונות, ומכיר את עולם הטכני של הפיתוח.
      </TipSection>

      <ArticleHeaderOne>למה כותבים מסמך אפיון אפליקציה</ArticleHeaderOne>

      <Paragraph>
        ניתן לדמות בניה של אפליקציה או כל מערכת תוכנה אחרת לבניה של מבנה. אפיון פונקציונאלי עבור האפליקציה זה כמו תוכנית
        אדריכל עבור מבנה שרוצים לבנות. לפני שאנחנו באים לבנות בניין, אנחנו חייבים לקבל את כל המפרט שלו: לאיזה מטרה אנחנו
        בונים את המבנה? מגורים או מסחר? כמה קומות צריכות להיות במבנה? האם יש מרתף? כמה דירות בכל קומה? איפה אנו נמקם את
        המעליות? כמה מעליות יש? האם בכלל יש תקציב למעליות בשלב זה? כמה דירות בכל קומה? כמה חלונות בכל דירה? מאיזה חומר
        אנחנו נבנה את המבנה? כמו שניתן לראות, אנחנו צריכים להבין את המפרט המלא של הבניין לפני שתהיה לנו יכולת להבין מה
        רוצים לבנות ואת המאמץ הנדרש לבניית הבניין הרצוי.
      </Paragraph>

      <Paragraph>
        הניסיון שלנו מלמד שכתיבת אפיון פונקציונאלי לאפליקציה זהו שלב חובה בדרך לפרויקט מצליח. לפני שאנחנו יכולים להתחיל
        לבנות את האפליקציה, אנחנו צריכים מסמך אשר מרכז באופן מסודר ומזוקק את הדרישות העסקיות ואת ההתנהגות הרצויה של
        האפליקציה או המערכת.
      </Paragraph>

      <Paragraph>
        מסמך אפיון פונקציונלי לאפליקציה מאפשר לנו:
        <ul>
          <li>
            הצגה טובה יותר של הרעיון: אפיון פונקציונאלי מציג בצורה מזוקקת ורשמית את הרעיון לכל הגורמים המעורבים בפרויקט
            כולל למפתחי התוכנה, ואפילו עוזר ליזם עצמו להבין את הרעיון שלו טוב יותר
          </li>
          <li>
            עמידה בציפיות: מצד אחד, צוות הפיתוח אמור להבין בצורה ברורה מה האפליקציה אמורה לעשות ואת ההתנהגות הצפויה שלה
            על מנת לפתח אותה. מצד שני, האפיון מתאר את המוצר שהלקוח מצפה לקבל
          </li>
          <li>
            נקודת מבט אחידה של כל הגורמים המעורבים בפרויקט: האפיון ממקד את צוות הפיתוח ואת כל הגורמים המעורבים ביכולות
            של האפליקציה, ומהווה מקור אחד של אמת לכל המעורבים בפרויקט
          </li>
          <li>
            גילוי מנגנונים נסתרים: ייתכן שיש צורך במנגנונים שיעבדו "מתחת למכסה מנוע" על מנת שאפשר יהיה לאפשר
            פונקציונליות מסוימת באפליקציה. על מנת לגלות את אותם המנגנונים, יש צורך להבין לעומק את ההתנהגות הצפויה של
            האפליקציה. תהליך כתיבת אפיון הפונקציונאלי עוזר לנו לחשוב על הדרישות מנקודות מבט שונות, לגלות את המנגנונים
            הנסתרים ולתעד אותם, לפני שאנחנו מגיעים לשלב הפיתוח
          </li>
          <li>
            שליטה בתקציב: לאחר ביצוע מיפוי של כל היכולות והמרכיבים של האפליקציה, ישנה יכולת להעריך בצורה טובה יותר את
            כמות העבודה הנדרשת להשלמת המשימה. לאחר שיש לנו את הרשימה של כל היכולות שהאפליקציה צריכה להיות מסוגלת לבצע,
            ניתן לשלוט על התקציב של הפרויקט על ידי בחירה ותעדוף של היכולות רלוונטיות ביותר לשלב בו נמצא הפרויקט
          </li>
          <li>
            חיסכון בזמן: אפיון פונקציונאלי חוסך את הזמן הנדרש לצירוף גורמים וחברי צוות חדשים לפרויקט, שכן ישנו תיעוד
            מסודר שמתאר את כל היכולות של האפליקציה. כך לדוגמא מעצב ממשק המשתמש מסתמך על מסמך האפיון על מנת לעצב טוב יותר
            את חווית המשתמש של האפליקציה
          </li>
          <li>
            איכות ובדיקות: כאשר מבצעים בדיקות איכות לאפליקציה, בודקי התוכנה משתמשים במסמך האפיון כעוגן לבדיקת היכולות
            שהם צריך לבדוק, מכיוון שהאפיון מתאר את ההתנהגות הרצויה של האפליקציה
          </li>
        </ul>
        כפי שניתן לראות, מסמך האפיון הפונקציונאלי של האפליקציה הינו אחד העמודים העיקריים עליהם נשען חלק נכבד מתהליך
        העבודה וכלל הגורמים המעורבים בפרויקט.
      </Paragraph>

      <TipSection>
        רצוי מאוד לכתוב את מסמך האפיון הפונקציונאלי בשפה האנגלית, שכן הדבר מאפשר גמישות מירבית בהתקשרות עם הספקים השונים
        והמשקיעים.
      </TipSection>

      <ArticleHeaderOne>מה כולל מסמך אפיון אפליקציה</ArticleHeaderOne>

      <Paragraph>
        בדרך כלל מסמך אפיון האפליקציה יהיה בנוי מחלקים מוגדרים מראש אשר מטרתם לתאר את המערכת בצורה הברורה והמזוקקת
        ביותר. תלוי באופי האפליקציה או המערכת והצורך של הפרויקט נכון לאותו הרגע, עשוי מסמך האפיון של האפליקציה לכלול או
        להחסיר חלקים מסוימים. כמובן שיש לקבל החלטה מודעת ומושכלת לגבי הסעיפים אותם מוסיפים או מורידים.
        <br />
        אפיון יכלול בדרך כלל את החלקים הבאים:
      </Paragraph>

      <ArticleHeaderTwo>מבוא</ArticleHeaderTwo>

      <Paragraph>
        בחלק זה אנו נתאר את הפרויקט ממבט על, את המטרות הכלליות של האפליקציה, את המטרות של המסמך עצמו, את קהל היעד של
        המסמך, וכל מידע כללי אחר אשר ייחודי עבור על פרויקט ספציפי.
      </Paragraph>

      <ArticleHeaderTwo>תיאור המערכת והיעוד שלה</ArticleHeaderTwo>

      <Paragraph>
        בחלק זה של המסמך אנו נצלול לתיאור מפורט של האפליקציה או המערכת, נתאר את הרכיבים העיקריים של המערכת ונתאר את
        הגורמים השונים אשר יש להם אינטראקציה עם האפליקציה (לדוגמא סוגים שונים של משתמשים ומערכות אחרות שצריכות להתממשק
        עם האפליקציה)
      </Paragraph>

      <ArticleHeaderTwo>תרחישי שימוש (Use Cases)</ArticleHeaderTwo>

      <Paragraph>
        חלק זה של המסמך כולל תיאור מפורט של כל תרחישי השימוש של האפליקציה או המערכת. תיאור תרחישי שימוש הינה הדרך
        המקובלת לאסוף ולנתח את הפונקציונליות והתנהגות של האפליקציה. כל תרחיש שימוש מתאר את רצף האירועים המתרחש בתרחיש,
        את תנאי ההתחלה, את השחקנים השונים שלוקחים חלק בתרחיש, את הקלט והפלט של התרחיש, את הכללים העסקיים של אותו התרחיש
        וסעיפים נוספים לפי הצורך.
        <br />
        שיטה כתיבה זו מאפשרת לתאר במילים פשוטות ולא טכניות את החוויה הכללית של המשתמש עם האפליקציה או המערכת.
      </Paragraph>

      <ArticleHeaderTwo>שרטוט סכמטי של מסכים האפליקציה (Wireframes)</ArticleHeaderTwo>

      <Paragraph>
        שרטוט סכמטי של מסכים בעצם מהווה מדריך חזותי המייצג את מסגרת השלד של האפליקציה. באמצעותו אנו מתארים את המיקום של
        הפקדים והשדות השונים של האפליקציה על גבי המסך. מטרת הסרטוט הינה להציג ולמקם את האלמנטים הוויזואליים השונים של
        האפליקציה על גבי המסך בצורה שישיגו את ההתנהגות הרצויה בצורה הטובה ביותר.
      </Paragraph>

      <Paragraph>
        מטרתו העיקרית של השרטוט הסכמתי הינה לתאר פונקציונאליות ואת ההתנהגות של האפליקציה ולכן השרטוט הסכמתי אינו מתעסק
        בבחירה של גופנים, התאמה של צבעים ואלמנטים גרפיים שונים אלא בפריסה של אלמנטים השונים על גבי המסך. בשרטוט אנו נתאר
        את המיקום של שדות המידע השונים, את הפקדים השונים, את האלמנטים השונים של הניווט, את לחצני הפעולה, תיבות סימון,
        תיבות רדיו וכדומה.
      </Paragraph>

      <Paragraph>
        בשלב זה אנו משתדלים להציג את המידע ולמקם את האלמנטים השונים בצורה כזאת שלמשתמש יהיה נח לעבוד עם הממשק בצורה
        הנוחה ביותר.
      </Paragraph>

      <Paragraph>
        במילים פשוטות, בשלב זה של האפיון אנחנו מתמקדים בחווית משתמש, מה כל מסך עושה ולא באיך הוא נראה.
      </Paragraph>

      <TipSection>
        שרטוט סכמטי של מסכי האפליקציה מאפשר פיתוח ממשק האפליקציה כבר בשלב המוקדם של הפרויקט. ניתן "להלביש" ממשק מעוצב על
        גבי מסכים קיימים ולחדד את חווית משתמש בשלב מאוחר יותר של הפרויקט.
      </TipSection>

      <ArticleHeaderTwo>סיכום ומסקנות</ArticleHeaderTwo>

      <Paragraph>
        בחלק זה של המסמך ראוי להתייחס למשימות הבאות שיש לבצע, כמו כן להתייחס לנקודות חשובות ומשמעותיות שיש לתת עליהן את
        הדעת בעת ביצוע הפרויקט.
        <br />
        חלק של הסיכום והמסקנות יכול לכלול גם נספחים רלוונטיים כמו תרשימים וכל מידע נוסף.
      </Paragraph>

      <Paragraph>
        מניסיון שלנו, תהליך ביצוע אפיון אפליקציה עם לקוח יכלול את השלבים הבאים:
        <ul>
          <li>פגישה ראשונית עם הלקוח על מנת להבין את הדרישות ואת מהות הפרויקט</li>
          <li>מיפוי ותיעוד של כל תרחישי השימוש השונים (UML Diagram)</li>
          <li>מעבר עם הלקוח על תרחישי שימוש שבוצע להם מיפוי, זיהוי ומיפוי פערים</li>
          <li>יצירה של מסכי מערכת באמצעות שרטוטים סכמטיים (באמצעות Figma)</li>
          <li>מעבר עם הלקוח על מסכי המערכת, זיהוי ומיפוי פערים</li>
          <li>יצירה של מסמך אפיון סופי המתעד את המערכת</li>
        </ul>
        חשוב להבין שתהליך האפיון הינו תהליך איטרטיבי עם הלקוח, וחלק משלבי התהליך עשויים לחזור על עצמם עד להשגת התוצאה
        הרצויה.
      </Paragraph>
    </Fragment>
  );
}
