import React from 'react';
import Typography from '@mui/material/Typography';

export default function ArticleHeader(props) {
  return (
    <Typography variant="h3" sx={{ marginTop: 7 }}>
      {props.children}
    </Typography>
  );
}
