import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';

export default function BannerArticles() {

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/articles/articles-banner-1920x500.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '200px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >

      {/* this is just for the overlay, the content is not inside of it */}
      <Box
        sx={{
          // md and up display is none, else use the block
          // the first element is: this size and up meaning md and up, use the none property
          // if the size is md and up then use the none, else use the block
          // display: { lg: 'none', xs: 'block' },
          // if want to use the: this size and up condition, then place it as second condition
          // if the size is md and up then use block, else use the none
          // display: { xs: 'none', md: 'block' },
          display: 'block',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: '#00000033',
          [theme.breakpoints.down('lg')]: {
            display: 'block',
            backgroundColor: '#00000033',
          },
          [theme.breakpoints.down('sm')]: {
            display: 'block',
            backgroundColor: '#00000033',
            backdropFilter: 'blur(5px)',
          },
        }}
      />

      <Box
        sx={{
          position: 'relative',
          paddingRight: 5,
        }}
      >
        <Typography variant="h4" sx={{ color: '#FFFFFF' }}>
          מאמרים
        </Typography>
      </Box>

    </Box>
  );
}