import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';

export default function TipSection(props) {

  return (
    <Paper variant="outlined"
      sx={{
        padding: 3,
        marginBottom: 2,
        backgroundColor: '#e7f0e4ff'
      }}
    >
      <Box>
        <Typography variant="body1" >
          {props.children}
        </Typography>
      </Box>
    </Paper >
  );

}
