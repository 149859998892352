import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function ServiceCard(props) {
  const data = props.data;

  return (

    <Box
      sx={{
        margin: 0,
        //backgroundColor: '#e36c41ff',
      }}
    >
      <CardMedia image={data.image} title={data.title}
        sx={{
          height: 160,
          marginBottom: 2,
        }}
      />

      <Box>
        <Typography variant="h6" color="textPrimary" >
          {data.title}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {data.content}
        </Typography>
      </Box>
    </Box>
  );
}