import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function AvatarWithText(props) {
  const data = props.data;
  return (
    <Box
      sx={{
        // backgroundColor: '#e36a3d',
        marginBottom: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 1,
        }}
      >
        <img alt={data.title} src={data.image} width='60' height='60' />
      </Box>

      <Box >
        <Typography variant="body2"
          sx={{
            textAlign: 'center'
          }}
        >
          {data.title}
        </Typography>
      </Box>
    </Box>
  );
}