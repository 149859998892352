import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';

export default function Banner() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/home/banner-1600x550.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '500px',
        [theme.breakpoints.down('sm')]: {
          minHeight: '450px',
          backgroundPosition: 'left',
        },
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >

      {/* this is just for the overlay, the content is not inside of it */}
      <Box
        sx={{
          // md and up display is none, else use the block
          // the first element is: this size and up meaning md and up, use the none property
          // if the size is md and up then use the none, else use the block
          // display: { lg: 'none', xs: 'block' },
          // if want to use the: this size and up condition, then place it as second condition
          // if the size is md and up then use block, else use the none
          // display: { xs: 'none', md: 'block' },
          display: 'none',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          [theme.breakpoints.down('lg')]: {
            display: 'block',
            backgroundColor: '#eef3f890',
          },
          [theme.breakpoints.down('sm')]: {
            display: 'block',
            backgroundColor: '#eef3f890',
            backdropFilter: 'blur(5px)',
          },
        }}
      />

      <Box sx={{ position: 'absolute', bottom: 0, right: 0, left: 0, backgroundColor: `${theme.palette.common.greenApp}`, display: 'block', height: '1px', }} />

      <Box
        sx={{
          position: 'relative',
          color: `${theme.palette.common.black}`,
          paddingRight: 5,
          paddingLeft: 5
        }}
      >
        <Typography variant="h3"
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            [theme.breakpoints.down('lg')]: {
              fontSize: '2.4rem',
            },
          }}>
          פיתוח אפליקציות ופתרונות תוכנה
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          הופכים את הרעיון להצלחה באמצעות טכנולוגיה ואנשים
        </Typography>
        <Typography variant="body1" display="block" >
          מתמחים בפיתוח אפליקציות, בניית אתרים ופתרונות תוכנה מרעיון להשקה, כולל תחזוקה
        </Typography>
        <Typography variant="body1" display="block" sx={{ marginBottom: 3 }}>
          עובדים עם כלים עדכניים, בצורה מקצועית מנוהלת ושקופה על מנת לספק פתרונות משתלמים
        </Typography>

      </Box>
      
    </Box>
  );
}
