import React from 'react';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import * as routes from 'util/routes';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';

export default function ArticleEntry(props) {

  const article = props.data;

  return (

    <Stack component={RouterLink} to={`${routes.ARTICLES}/${article.url}`}
      sx={{
        textDecoration: 'none',
        color: "#000000",
        width: 300,
        marginBottom: 2,
      }}
    >

      <CardMedia
        image={article.image}
        title={article.title}
        sx={{
          height: 160,
        }}
      />

      <Typography variant="h6">
        {article.title}
      </Typography>

      <Typography variant="body1" >
        {article.description}
      </Typography>

    </Stack>

  );
}