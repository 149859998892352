import React, { Fragment } from 'react';
import ArticleEntry from 'components/articles/ArticleEntry';
import articlesData from 'modules/articles/articlesData';
import Grid from '@mui/material/Grid';

export default function ArticleGrid() {
  return (
    <Fragment>
      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="flex-start"
        sx={{
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        {articlesData.map((article) => (
          <Grid item key={article.url}>
            <ArticleEntry data={article} />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
}
