import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ServiceCard from 'components/cards/ServiceCard'
import serviceDataCards from 'components/banners/serviceCardsData';
import SectionTitle from 'components/cards/SectionTitle';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function BannerServices() {
  const theme = useTheme();
  // https://mui.com/components/use-media-query/
  // use the media query to check the size of the screen
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  // return the proper justify content parameter based on the screen
  // if larger than sm then use space-between else use center
  const gridJustifyContent = () => {
    if (isSm) return "center";
    else return "space-between";
  };

  return (
    <Box
      sx={{
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <SectionTitle
        data={
          {
            "title": "שירותים",
            "description": [
              "אנו מספקים מגוון שירותים בתחום פיתוח אפליקציות ותוכנה",
              "יעוץ, חקר ישימות פתרון, איסוף דרישות הלקוח והתאמת הטכנולוגיות המתאימות ביותר לביצוע הפרויקט",
            ]
          }
        }
      />

      <Grid
        container
        // the default size is 15 columns
        // when the size of the screen is lg and up, each width of each column should be 3, meaning 5 columns
        // when the size of the screen is md and up, each width of each column should be 5, meaning 3 columns
        // when the size of the screen is sm and up, each width of each column should be 7, meaning 2 columns
        // when the size of the screen xs sm and up, each width of each column should be 15, meaning 1 columns

        columns={15}
        spacing={3}
        justifyContent={gridJustifyContent()}
      >
        <Grid item xs={12} sm={7} md={5} lg={3}>
          <ServiceCard data={serviceDataCards.functionalSpec} />
        </Grid>
        <Grid item xs={12} sm={7} md={5} lg={3}>
          <ServiceCard data={serviceDataCards.appDevelopment} />
        </Grid>
        <Grid item xs={12} sm={7} md={5} lg={3}>
          <ServiceCard data={serviceDataCards.webDevelopment} />
        </Grid>
        <Grid item xs={12} sm={7} md={5} lg={3}>
          <ServiceCard data={serviceDataCards.backendDevelopment} />
        </Grid>
        <Grid item xs={12} sm={7} md={5} lg={3}>
          <ServiceCard data={serviceDataCards.softwareSolutions} />
        </Grid>
      </Grid>
    </Box>
  );
}
