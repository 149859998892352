import * as routes from 'util/routes';

const articlesData = [
  {
    title: 'עלות פיתוח אפליקציה',
    image: '/images/articles/application-cost.jpg',
    published: 'נכתב על ידי רוסלן ספיבק',
    description: 'כמה באמת עולה לפתח אפליקציה?',
    url: `${routes.URL_ARTICLE_APP_PRICE}`,
  },
  {
    title: 'סוגים של אפליקציות מובייל',
    image: '/images/articles/application-types.jpg',
    published: 'נכתב על ידי רוסלן ספיבק',
    description: 'סקירת טכנולוגיות לבניית אפליקציות',
    url: `${routes.URL_ARTICLE_APP_TECH}`,
  },
  {
    title: 'אפיון פונקציונאלי לאפליקציה',
    image: '/images/articles/functional-specification.jpg',
    published: 'נכתב על ידי רוסלן ספיבק',
    description: 'למה חשוב לכתוב מסמך אפיון הפונקציונאלי?',
    url: `${routes.URL_ARTICLE_FUNC_SPEC}`,
  },
];

export default articlesData;
